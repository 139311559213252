@import 'variables';

.mat-calendar-body-cell {
    font-weight: bold;

    &.mat-calendar-body-disabled {
        font-weight: normal;
    }
}

img.ngx-ic-source-image {
    margin: 0 auto
}