// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// include ag-grid-angular
@use '~ag-grid-community/styles' as ag;
@include ag.grid-styles(
  (
    theme: alpine
  )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$xgenflix-primary: mat.define-palette(mat.$indigo-palette);
$xgenflix-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$xgenflix-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$xgenflix-theme: mat.define-light-theme(
  (
    color: (
      primary: $xgenflix-primary,
      accent: $xgenflix-accent,
      warn: $xgenflix-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($xgenflix-theme);

$family-sans-serif: basic-sans, sans-serif;
$size-normal: 16px;
$size-6: 18px;
$gap: 48px;

@import '~bulma';
@import '~bulma/sass/utilities/mixins';
@import url('https://use.typekit.net/cqe0znu.css');
@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;700&display=swap');

@import 'variables';

html {
  overflow-y: auto;
  background: none;
}

img {
  display: block;
}

::-webkit-scrollbar {
  appearance: none;
  width: 12px;
  height: 12px;
  transition: 250ms ease;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #808080;
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;

  &:hover {
    border-width: 2px;
  }
}

body {
  font-size: $size-normal;
  background: #202020;
  color: #ffffff;
  line-height: 1.2;
}

a {
  color: $tribunal-left-complementary-light;

  &:hover {
    color: $tribunal-left-complementary-light;
  }
}

div {
  outline: none;
}

.container {
  @include touch {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.is-size-1,
.is-size-2 {
  line-height: 1;
}

.is-primary {
  background: $tribunal;
}

.button {
  border-color: transparent;
  border-radius: 0 !important;

  &:hover {
    border-color: transparent;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    border: none;
  }

  &.is-small {
    font-size: 12px;
    height: unset;
  }

  &.is-primary {
    background: $tribunal;

    &:hover {
      background: lighten($tribunal, 5%);
    }

    &:active {
      background: $tribunal;
    }

    &:disabled {
      background: $tribunal;
    }

    &.is-outlined {
      border-color: $tribunal !important;
      color: $tribunal !important;

      &:focus {
        background: none !important;
      }

      &:hover {
        background: $tribunal !important;
        color: lighten($tribunal, 100%) !important;
      }

      &:active {
        border-color: $tribunal !important;
        color: $tribunal !important;
      }

      &:disabled {
        border-color: $tribunal !important;
        color: $tribunal !important;
      }
    }
  }

  &.is-secondary {
    background: $background;
    color: #ffffff;

    &:hover {
      background: $background-lighter-100;
    }

    &:active {
      background: $background;
    }

    &:disabled {
      background: $background;
    }
  }

  &.is-outlined {
    border: #ffffff solid 1px;
    background: none;
    color: #ffffff;

    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }

  &.is-borderless {
    border: none !important;
  }

  &:focus:not(:active) {
    box-shadow: none;
  }

  &.is-ghost {
    background: none !important;
    border-color: transparent;
    color: #ffffff;
    text-decoration: none !important;

    &:hover {
      color: $tribunal !important;
    }
  }

  &.underline {
    text-decoration: underline;
  }

  &.is-circle {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50% !important;
  }
}

.full-height {
  height: 100%;

  /*@media screen and (max-width: 768px) {
    height: 75%;
  }*/
}

.full-width {
  width: 100%;
}

.navbar {
  background: unset;
  height: 64px;

  a {
    color: unset;
  }
}

.has-text-primary {
  color: $tribunal-left-complementary-light !important;
}

.has-text-highlight {
  color: $honey !important;
}

.has-text-muted {
  color: rgba(#ffffff, 0.6) !important;
}

.is-overflow-hidden {
  overflow: hidden;
}

.is-horizontal-scroll {
  overflow-x: scroll;
}

.loading {
  position: relative;
  background-color: $background-lighter-100;
  overflow: hidden;
  cursor: initial !important;
  z-index: 1;

  &.loading-dark {
    background-color: darken($background-lighter-100, 20%);
  }

  &.text-loader {
    width: 160px;
    height: 24px;
    position: relative;

    &.description {
      width: 256px;
    }

    &.button {
      width: 80px;
      height: 32px;
    }
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.1) 50%, transparent 100%);
    // background: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 0.1) 50%, transparent 100%);
    animation: loading-animation 4s infinite;
  }

  $heights: 38, 232, 24, 280;

  @each $height in $heights {
    &.h-#{$height} {
      height: #{$height}px;
    }
  }
}

@keyframes loading-animation {
  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.field {
  .label {
    font-size: 12px;
    margin-bottom: 0.25rem;
    color: #ffffff;
    font-weight: 400;
  }

  .icon {
    color: rgba(#ffffff, 0.6) !important;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .input {
    background: unset;
    color: #ffffff;
    border-radius: 0;
    border-color: $background-lighter-200;

    &:hover {
      border-color: $background-lighter-200;
    }

    &:active {
      box-shadow: unset !important;
      border-color: unset;
    }

    &:focus {
      box-shadow: unset;
      background: $background-lighter-100;
      border-color: transparent;

      & ~ .icon {
        color: #ffffff !important;
      }
    }

    &::placeholder {
      color: rgba(#ffffff, 0.6);
    }

    &:disabled {
      background: darken($background-darker-100, 5%);
      color: rgba(#ffffff, 0.4);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.top-pad {
  height: 56px;
}

.card {
  // border: 1px solid #ffffff;
  border-radius: 0;
  color: #ffffff;
  background: $background-darker-100;
  padding: 1rem 2rem;
  cursor: pointer;

  &.has-hover:hover {
    // background: #ffffff;

    background: $background-lighter-200;
    // color: $background;
  }

  &.is-danger {
    border-color: $danger;
    color: $danger;
  }
}

.table {
  width: 100%;
  background: none;
  color: #ffffff;

  thead tr th {
    font-weight: 600;
    color: #ffffff;
  }

  tbody tr td {
    border-color: rgba(#ffffff, 0.4);
    font-size: 14px;
  }

  th,
  td {
    padding: 0.75rem 1rem;
  }
}

.flexbox {
  display: flex;
  align-items: center;

  &.wrap {
    flex-wrap: wrap;
  }
}

.flex {
  flex: 1;
}

@include mobile {
  .search-bar {
    width: 40vw !important;
  }

  .banner-spread {
    align-items: flex-end !important;
    text-align: center;
    padding-bottom: 3rem;
  }

  .sound {
    right: 24px !important;
    bottom: 24px !important;
  }

  .actors {
    justify-content: center;
  }

  .gallery {
    justify-content: center;
  }
}

.w-100 {
  width: 100%;
}

.w-79 {
  width: 79%;
}

.w-50 {
  width: 50%;
}

.ng-scrollbar {
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.6) !important;
  --scrollbar-size: 4px !important;
  --scrollbar-hover-size: 8px !important;
}

.buttons {
  margin-bottom: 0 !important;

  .button {
    margin-bottom: 0 !important;
  }
}

[class^='xgenflix-'] {
  &.md {
    font-size: 24px;
  }

  &.lg {
    font-size: 32px;
  }
}

.minor-navigation {
  background-color: #232931;
  height: 64px;
  position: fixed;
  top: 64px;
  width: 100vw;
  z-index: 10;
}

.select {
  select {
    border-radius: 0;
  }

  option {
    background-color: $background;
  }

  &.is-outlined {
    select {
      background: none;
      border-color: #ffffff;
      color: #ffffff;
    }
  }

  &::after {
    content: '\f078' !important; /* Unicode for Font Awesome chevron down icon */
    font-family: 'Font Awesome 5 Free'; /* Adjust this to match your Font Awesome setup */
    font-size: 14px;
    font-weight: 900;
    border: unset !important;
    top: 50%;
    transform: translateY(-50%) !important;
    margin: auto !important;
    height: 24px !important;
    width: unset !important;
    display: flex !important;
    align-items: center;
  }
}

.tabs {
  ul {
    border: none;
  }

  a {
    margin: 0;
    color: #ffffff;
    border: 0;

    &:hover {
      background: rgba($tribunal-left-complementary-light, 0.2);
      color: #ffffff;
    }
  }

  li {
    &.is-active a {
      color: lighten($tribunal-left-complementary-light, 5%);
      background: rgba($tribunal-left-complementary-light, 0.4);
    }
  }
}

.h-100 {
  height: 100%;
}

.supermodal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, 0.8);
  z-index: 100;

  .btn-close {
    position: absolute;
    top: 0;
    right: -40px;
    width: 32px;
    height: 32px !important;
    line-height: 1;
    padding: 0;
    background-color: #353535;

    img {
      height: 16px;
    }
  }
}

.l-0 {
  line-height: 0;
}

.razor {
  transition: all 100ms ease;

  &:hover {
    transform: scale(1.025);
    color: $tribunal;

    .thumbnail-img-inner-wrapper {
      outline: 4px solid $tribunal-darker;
    }

    .movie-title,
    .details {
    }
  }

  .thumbnail-img-inner-wrapper {
    outline: 4px solid transparent;
  }

  .movie-title {
    color: #ffffff;
  }
}

.twilio {
  .local {
    width: 200px;
    height: 160px;
    position: absolute;
    top: 3rem;
    right: 3rem;
    background-color: black;
    z-index: 1000;

    @include touch {
      top: 0;
      right: 0;
      width: 100px;
      height: 120px;
    }

    .local-video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 16 / 9;
    }

    .invisible {
      display: none;
    }
  }

  .participant {
    background-color: black;

    &.invisible {
      display: none;
    }

    .track {
      video {
        width: 100%;
        height: calc(100vh);
        margin: 0 auto;
      }
    }

    &.small-preview {
      .track {
        video {
          width: 280px;
        }
      }
    }
  }
}

.modal-open {
  height: 100vh;
  overflow: hidden;
}

.shephard {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-top: 1rem;
  position: relative;

  &.actor-studio {
    .thumbnail-img-inner-wrapper {
      aspect-ratio: 1 / 1 !important;
    }
  }

  .tile-slide {
    font-size: 42px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;

    &.left {
      left: -36px;
    }

    &.right {
      right: -36px;
    }

    &:hover {
      background: rgba(#000000, 0.6);
    }
  }

  .thumbnail-img-wrapper {
    flex-shrink: 0;
    overflow: hidden;
    width: calc(100% / 6);
    padding: 0.5rem;
    cursor: pointer;

    @include mobile {
      width: calc(100% / 2);

      &.index-6,
      &.index-7,
      &.index-8 {
        display: none;
      }
    }

    @include tablet-only {
      width: calc(100% / 3);
    }

    @include desktop-only {
      width: calc(100% / 4);

      &.index-8 {
        display: none;
      }
    }

    @include widescreen-only {
      width: calc(100% / 5);

      &.index-5,
      &.index-6,
      &.index-7,
      &.index-8 {
        display: none;
      }
    }

    @include fullhd {
      width: calc(100% / 6);

      &.index-6,
      &.index-7,
      &.index-8,
      &.index-9,
      &.index-10,
      &.index-11 {
        display: none;
      }
    }

    .thumbnail-img-inner-wrapper {
      display: flex;
      overflow: hidden;
      //border-radius: 8px;
      margin-bottom: 4px;
      aspect-ratio: 16 / 9;
      position: relative;

      .thumbnail-img {
        width: 100%;
        object-fit: cover;
      }
    }

    .movie-title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
  }
}

.gen-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .gen-avatar-img {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(0.4);
  }

  .gen-avatar-letter {
    font-size: 100px;
    font-weight: 700;
    color: #ffffff;
    z-index: 100;
    mix-blend-mode: difference;

    &.sm {
      font-size: 28px;
    }
  }
}

.unlock-card {
  background-color: darken($background-lighter-100, 10%);
  border: 1px solid lighten($background-lighter-100, 10%);
  cursor: default;

  .center-items {
    align-items: center;
    justify-content: center;
  }
}
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */

/* This container is needed to position the front and back side */

.credit-card {
  margin-left: 0;
  cursor: pointer;

  &.has-selected {
    .column:not(.selected) {
      opacity: 0.4;
    }
  }

  .column {
    padding: 0;

    .poster {
      img {
        height: 16px;
      }
    }
  }

  .flip-card {
    background-color: transparent;
    height: 140px;
    min-width: 240px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */

    .flip-card-inner {
      transition: transform 1s;
      transform-style: preserve-3d;

      border-radius: 8px;
      background-color: rgba(#929292, 0.4);
      border: thin solid #a0a0a0;

      &.default {
        background-color: #8d510c !important;
        border: thin solid #ff8000 !important;
      }

      &.selected {
        border: 4px solid $tribunal;
      }

      .card-number {
        bottom: 10px;
        position: absolute;
      }
    }

    &.rotate .flip-card-inner {
      transform: rotateY(180deg);
    }
  }

  /* Position the front and back side */
  .flip-card-front,
  .flip-card-back {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  /* Style the front side (fallback if image is missing) */

  /* Style the back side */
  .flip-card-back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;

    ul {
      z-index: 1000;
      li {
        &:hover {
          color: $tribunal;
        }

        &.remove:hover {
          color: red;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }
}

//ag-grid
.ag-header-row {
  border-bottom: 1px solid !important;
}
.ag-header-group-cell {
  font-size: 16px;
}
.ag-header-cell {
  font-size: 16px;
}

.ag-filter {
  background-color: #3b4653;
}

.ag-select .ag-picker-field-wrapper {
  background-color: #3b4653;
  color: #fff;
}

.ag-select-list {
  background-color: #3b4653;
  color: #fff;
}

.views-count {
  background-color: #3b4653;
  padding: 4px 8px;
  font-size: 0.75rem;
  color: #ffffff66;
}

.unlock {
  color: #d2ab45 !important;
}
