$tribunal: #be4961;
$tribunal-left-complementary-light: #ff7a95;
$tribunal-right-complementary-dark: #157325;
$tribunal-right-complementary-light: #49bf5d;
$honey: #e5811a;
$danger: #e71d36;
$background: #2b333c;
$background-darker-100: scale-color($background, $lightness: -10%);
$background-lighter-100: scale-color($background, $lightness: 10%);
$background-lighter-200: scale-color($background, $lightness: 20%);
$background-shade-800: #3b4653;

$tribunal-darker: scale-color($tribunal, $lightness: -20%);

$unused-1: #067bc2;
$unused-2: #81b29a;

$lowest-rating: #e71d36;    // Red
$low-rating: #ff9f1c;        // Orange
$average-rating: #ffd966;    // Yellow
$good-rating: #4caf50;       // Green
$excellent-rating: #2196f3;  // Blue

