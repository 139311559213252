$tr-bell-on: '\ea01';
$tr-caret-down: '\ea02';
$tr-caret-up: '\ea03';
$tr-close: '\ea04';
$tr-close-medium: '\ea05';
$tr-compact-chevron-left: '\ea06';
$tr-compact-chevron-right: '\ea07';
$tr-event: '\ea08';
$tr-favorites: '\ea09';
$tr-kudo: '\ea0a';
$tr-meet: '\ea0b';
$tr-messages: '\ea0c';
$tr-musolive: '\ea0d';
$tr-notifications: '\ea0e';
$tr-pause: '\ea0f';
$tr-play: '\ea10';
$tr-referral: '\ea11';
$tr-send: '\ea12';
$tr-share: '\ea13';
$tr-shout: '\ea14';
$tr-ticket: '\ea15';
$tr-users: '\ea16';
$tr-wow: '\ea17';

@font-face {
  font-family: 'treyicons';
  src: url('../font/treyicons.eot');
  src: url('../font/treyicons.eot?5iscbs#iefix') format('embedded-opentype'),
    url('../font/treyicons.woff2?5iscbs') format('woff2'), url('../font/treyicons.woff?5iscbs') format('woff'),
    url('../font/treyicons.ttf?5iscbs') format('truetype');
  font-style: normal;
  font-weight: 400;
}

%tr {
  display: inline-block;
  font-family: 'treyicons', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: -0.125em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tr {
  @extend %tr;
}

.tr-bell-on::before {
  content: '\ea01';
}
.tr-caret-down::before {
  content: '\ea02';
}
.tr-caret-up::before {
  content: '\ea03';
}
.tr-close::before {
  content: '\ea04';
}
.tr-close-medium::before {
  content: '\ea05';
}
.tr-compact-chevron-left::before {
  content: '\ea06';
}
.tr-compact-chevron-right::before {
  content: '\ea07';
}
.tr-event::before {
  content: '\ea08';
}
.tr-favorites::before {
  content: '\ea09';
}
.tr-kudo::before {
  content: '\ea0a';
}
.tr-meet::before {
  content: '\ea0b';
}
.tr-messages::before {
  content: '\ea0c';
}
.tr-musolive::before {
  content: '\ea0d';
}
.tr-notifications::before {
  content: '\ea0e';
}
.tr-pause::before {
  content: '\ea0f';
}
.tr-play::before {
  content: '\ea10';
}
.tr-referral::before {
  content: '\ea11';
}
.tr-send::before {
  content: '\ea12';
}
.tr-share::before {
  content: '\ea13';
}
.tr-shout::before {
  content: '\ea14';
}
.tr-ticket::before {
  content: '\ea15';
}
.tr-users::before {
  content: '\ea16';
}
.tr-wow::before {
  content: '\ea17';
}
